import React from 'react';
import Linkify from 'react-linkify';

const QuestionHeader = (props) => {
  const { text, descriptor, readOnly, isAnswerType } = props;

  const renderDescriptor = descriptor ? (
    <>
      <br />
      {descriptor}
    </>
  ) : null;

  return (
    <Linkify properties={{ target: '_blank' }}>
      {text}
      {renderDescriptor}
      {readOnly && ':'}
      {isAnswerType && !readOnly && <i className="fas fa-edit ml3" />}
    </Linkify>
  );
};

export default QuestionHeader;
